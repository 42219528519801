import { ISummaryItem } from 'components/shared/SummaryTable/types';
import { AxiosPrivateFirebaseInstance } from 'settings';
import { IInvoice, IResponse, ITransfer } from 'types';
import { IAccountsTableDataItem } from 'types/bff';
import { IPlatformEventsSearchResult } from 'types/events';

export const getAccountsTableData = () =>
  AxiosPrivateFirebaseInstance.get<IResponse<IAccountsTableDataItem[]>>(
    'bff/accounts'
  );

export const getAssetEvents = (assetId: string) =>
  AxiosPrivateFirebaseInstance.get<IResponse<IPlatformEventsSearchResult[]>>(
    `bff/events/${assetId}`
  );

export const getTransferInfo = (transferId: string) =>
  AxiosPrivateFirebaseInstance.get<
    IResponse<{
      transfer: ITransfer;
      invoices: IInvoice[];
    }>
  >(`bff/transfers/${transferId}`);

export const getCashflowSummaries = (shouldFetchForAllEntities: boolean) =>
  AxiosPrivateFirebaseInstance.get<IResponse<ISummaryItem[]>>(
    `bff/cashflows/summary${shouldFetchForAllEntities ? '?all=true' : ''}`
  );
