import { Dispatch, SetStateAction } from 'react';
import { IContact, IInvoiceFromSearch, PartialContact } from 'types';
import { ContactButton } from './ContactCellNew.styles';
import {
  ParagraphWithEllipsis,
  TextHintWithEllipsis,
} from '../Typography/Typography';
import ContactReviewStatus from '../ContactReviewStatus/ContactReviewStatus';
import { isReceivableInvoice } from 'utils/invoices';
import { Col } from '../Col/Col';
import { useTheme } from 'styled-components';

interface OwnProps {
  recipientToUse?: IContact | PartialContact;
  setContactForEdit?: Dispatch<
    SetStateAction<IContact | PartialContact | null>
  >;
  setInvoiceForAddContact?: Dispatch<SetStateAction<IInvoiceFromSearch | null>>;
  invoice: IInvoiceFromSearch;
  withReviewStatus?: boolean;
}

const ContactCellNew: React.FC<OwnProps> = ({
  recipientToUse,
  setContactForEdit,
  setInvoiceForAddContact,
  invoice,
  withReviewStatus = true,
}) => {
  const theme = useTheme();
  const { recipientName, id, enabled } = recipientToUse ?? {};
  const { contactId, externalRefsSourceSystemContactName } = invoice;
  const recipientNameToUse =
    recipientName ??
    externalRefsSourceSystemContactName ??
    'Contact is missing';
  const showSourceSystemName =
    recipientName &&
    externalRefsSourceSystemContactName &&
    recipientName !== externalRefsSourceSystemContactName;

  return (
    <ContactButton
      as="button"
      onClick={(e: any) => {
        e.stopPropagation();

        // contacts with ID from invoices
        if (recipientToUse && contactId && enabled && id) {
          setContactForEdit?.(recipientToUse);
        } else {
          setInvoiceForAddContact?.(invoice);
        }
      }}
    >
      <Col rowGap={theme.spacing.xxs} overflow="hidden">
        <ParagraphWithEllipsis maxWidth="150px">
          {recipientNameToUse}
        </ParagraphWithEllipsis>

        {showSourceSystemName && (
          <TextHintWithEllipsis maxWidth="150px">
            {externalRefsSourceSystemContactName}
          </TextHintWithEllipsis>
        )}
      </Col>

      {withReviewStatus && !isReceivableInvoice(invoice) && recipientToUse && (
        <ContactReviewStatus contact={recipientToUse} />
      )}
    </ContactButton>
  );
};

export default ContactCellNew;
