import { FC, useMemo, useState } from 'react';
import { ContextMenu } from 'components';
import { ITransaction } from 'types/transactions';
import { useHistory } from 'react-router';
import { getInvoiceDetailsLink, getTransfersPageLink } from 'utils/links';
import { useStoreState } from 'state';
import { canUseRateContract } from 'utils';
import { viewTransactionDetails } from 'utils/transactions';
import {
  useAllocatePrebooksPermissionsCheck,
  useCreateTransfersPermissionsCheck,
} from 'hooks/useSpecificPermissionsCheck';
import useUrlValues from 'hooks/useUrlValues';
import PopupSendRemittance from '../PopupSendRemittance/PopupSendRemittance';
import { TRANSFER_STATUS } from 'types';

interface OwnProps {
  transaction: ITransaction;
}

const DropdownMenuCell: FC<OwnProps> = ({ transaction }) => {
  const history = useHistory();
  const [sendRemittancePopupOpen, setSendRemittancePopupOpen] = useState(false);
  const { rateContractById } = useStoreState(
    (state) => state.RateContractsState
  );
  const { transferById } = useStoreState((state) => state.TransfersState);
  const { setUrlValue } = useUrlValues();

  const hasTransfersCreatePermission = useCreateTransfersPermissionsCheck();
  const hasAllocatePrebooksPermission = useAllocatePrebooksPermissionsCheck();

  const rateContract = rateContractById(transaction.id);
  const transfer = transferById(transaction.id);

  const contextMenuData = useMemo(() => {
    const dataToUse = [
      {
        id: 'seeDetails',
        title: 'See details',
        onClick: () => viewTransactionDetails(setUrlValue, transaction),
      },
    ];

    if (rateContract) {
      const { remainingBuyAmount, buyCurrency, id, invoiceId } = rateContract;

      if (
        canUseRateContract({ rateContract, hasAllocatePrebooksPermission }) &&
        hasTransfersCreatePermission
      ) {
        dataToUse.push({
          id: 'transferNow',
          title: 'Transfer now',
          onClick: () => {
            history.push(
              getTransfersPageLink({
                predefinedBuyAmount: remainingBuyAmount.toString(),
                predefinedBuyCurrency: buyCurrency,
                predefinedRateContractId: id,
              })
            );
          },
        });
      }

      if (invoiceId) {
        dataToUse.push({
          id: 'invoiceDetails',
          title: 'Invoice details',
          onClick: () => history.push(getInvoiceDetailsLink({ invoiceId })),
        });
      }
    }

    if (transfer && transfer.status === TRANSFER_STATUS.paymentSent) {
      dataToUse.push({
        id: 'sendRemittance',
        title: 'Send remittance email',
        onClick: () => setSendRemittancePopupOpen(true),
      });
    }

    return dataToUse;
  }, [
    hasTransfersCreatePermission,
    hasAllocatePrebooksPermission,
    history,
    rateContract,
    transfer,
    transaction,
    setUrlValue,
  ]);

  return (
    <>
      <ContextMenu portal list={contextMenuData} />

      {transfer && sendRemittancePopupOpen && (
        <PopupSendRemittance
          transferId={transfer.id}
          transferRecipient={transfer.recipient}
          onClose={() => setSendRemittancePopupOpen(false)}
        />
      )}
    </>
  );
};

export default DropdownMenuCell;
