import { FC, useState, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router';
import ReviewStep from './components/ReviewStep/ReviewStep';
import AuthoriseStep from './components/AuthoriseStep/AuthoriseStep';
import AuthoriseOpenBankingStep from './components/AuthoriseOpenBankingStep/AuthoriseOpenBankingStep';
import ConfirmationOpenBankingStep from './components/ConfirmationOpenBankingStep/ConfirmationOpenBankingStep';
import ConfirmationStep from './components/ConfirmationStep/ConfirmationStep';
import HorizontalStepper from 'pages/PaymentRun/components/HorizontalStepper/HorizontalStepper';
import { getDraftPaymentRun } from 'services/paymentRuns';
import { IPaymentRun } from 'types/paymentRuns';
import { Loader } from 'components';
import { errorHandler } from 'utils/errors';
import { getInvoicesPageLink } from 'utils/links';
import { useStoreState } from 'state';
import useUrlValues from 'hooks/useUrlValues';
import useOpenBankingPayments from 'hooks/useOpenBankingPayments';

const PaymentRun: FC = () => {
  const history = useHistory<{ invoiceIds: string[] } | undefined>();
  const { entityId } = useStoreState(({ UserState }) => UserState);
  const { featureFlagById } = useStoreState((state) => state.FeatureFlagsState);
  const {
    step: activeStep,
    openBanking: isOpenBankingFlow,
    setUrlValue,
  } = useUrlValues('step', 'openBanking');

  const [paymentRun, setPaymentRun] = useState<IPaymentRun>();
  const [isLoading, setIsLoading] = useState(true);
  const [isComplete, setIsComplete] = useState(false);
  const paymentRunNotPaidViaHedgeFlows =
    paymentRun?.instructions.notPaidViaHedgeFlows;
  const [
    openBankingBulkPaymentId,
    setOpenBankingBulkPaymentId,
  ] = useState<string>();

  const {
    selectedInstitution,
    openBankingSettings,
    setOpenBankingSettings,
    isLoadingOpenBankingSettings,
    institutions,
    isLoadingInstitutions,
    paymentAuthResponse,
    isLoadingPaymentAuthUrl,
    onPaymentAuthorisationInstitutionSelect,
  } = useOpenBankingPayments({
    fetchInstitutions: featureFlagById('openBankingPaymentsForBulkPayments'),
    fetchOpenBankingSettings: featureFlagById(
      'openBankingPaymentsForBulkPayments'
    ),
    assetId: paymentRun?.id,
    // On first step of payment-run we don't need to show AuthResponse error
    fetchAuthResponse: !!activeStep && parseInt(activeStep) === 3,
  });

  const getPaymentRunHandler = useCallback(
    async (openBanking?: boolean) => {
      if (!entityId) {
        return;
      }
      try {
        const { data } = await getDraftPaymentRun({
          entityId,
          generate: true,
          openBanking: !!openBanking,
        });

        setPaymentRun(data.data);
        setIsLoading(false);
      } catch (error: any) {
        errorHandler(error);
        history.goBack();
      }
    },
    [entityId, history]
  );

  useEffect(() => {
    if (!paymentRun) {
      getPaymentRunHandler();
    }
  }, [getPaymentRunHandler, paymentRun]);

  useEffect(() => {
    if (
      activeStep &&
      ((Number(activeStep) > 1 && !paymentRun) ||
        (Number(activeStep) !== 3 && isComplete))
    ) {
      history.replace(
        getInvoicesPageLink({
          currency: 'all',
        })
      );
    }
  }, [activeStep, history, isComplete, paymentRun]);

  const goToStep = ({
    stepNumber,
    openBanking = false,
  }: {
    stepNumber: number;
    openBanking?: boolean;
  }) =>
    setUrlValue({
      step: `${stepNumber}`,
      openBanking: openBanking ? 'true' : '',
    });

  const renderSteps = (step: string) => {
    return (
      <HorizontalStepper
        activeStep={parseInt(step)}
        data={[
          {
            title: 'Review',
            onClick: () => goToStep({ stepNumber: 1 }),
            disabled: parseInt(step) === 3,
          },
          {
            title: isOpenBankingFlow ? 'Consent' : 'Authorise',
            onClick: () =>
              goToStep({ stepNumber: 2, openBanking: !!isOpenBankingFlow }),
            disabled: parseInt(step) === 3,
          },
          {
            title: 'Confirmation',
            onClick: () =>
              goToStep({ stepNumber: 3, openBanking: !!isOpenBankingFlow }),
            disabled: parseInt(step) === 3,
          },
        ]}
      >
        {renderContent()}
      </HorizontalStepper>
    );
  };

  const renderContent = () => {
    if (isLoading) {
      return <Loader size="large" />;
    }

    if (!activeStep || !paymentRun) {
      return null;
    }

    switch (parseInt(activeStep)) {
      case 1:
        return (
          <ReviewStep
            paymentRun={paymentRun}
            setPaymentRun={setPaymentRun}
            onContinue={async (openBanking: boolean) => {
              if (!openBanking && !!paymentRunNotPaidViaHedgeFlows) {
                await getPaymentRunHandler(false);
              }
              if (openBanking && !paymentRunNotPaidViaHedgeFlows) {
                await getPaymentRunHandler(true);
              }

              goToStep({ stepNumber: 2, openBanking });
            }}
            openBankingSettings={openBankingSettings}
            setOpenBankingSettings={setOpenBankingSettings}
            getPaymentRunHandler={getPaymentRunHandler}
            institutions={institutions}
            isLoadingInstitutions={isLoadingInstitutions}
          />
        );
      case 2:
        return !!isOpenBankingFlow ? (
          <AuthoriseOpenBankingStep
            paymentRun={paymentRun}
            onContinue={() => {
              goToStep({ stepNumber: 3, openBanking: true });
              setIsComplete(true);
            }}
            institution={selectedInstitution}
            setOpenBankingBulkPaymentId={setOpenBankingBulkPaymentId}
            isLoading={isLoading || isLoadingOpenBankingSettings}
          />
        ) : (
          <AuthoriseStep
            paymentRun={paymentRun}
            setPaymentRun={setPaymentRun}
            onContinue={() => {
              goToStep({ stepNumber: 3 });
              setIsComplete(true);
            }}
          />
        );
      case 3:
        return !!isOpenBankingFlow ? (
          <ConfirmationOpenBankingStep
            paymentRunTotals={paymentRun.paymentRunTotals}
            data={paymentRun.paymentRunItemSummary || []}
            institution={selectedInstitution}
            paymentAuthResponse={paymentAuthResponse}
            isLoadingPaymentAuthUrl={isLoadingPaymentAuthUrl}
            onPaymentAuthorisationInstitutionSelect={
              onPaymentAuthorisationInstitutionSelect
            }
            bulkPaymentId={openBankingBulkPaymentId}
          />
        ) : (
          <ConfirmationStep
            paymentRunTotals={paymentRun.paymentRunTotals}
            paymentDate={paymentRun.instructions.paymentDate ?? ''}
            data={paymentRun.paymentRunItemSummary || []}
          />
        );
      default:
        return null;
    }
  };

  if (!activeStep) {
    return null;
  }

  return renderSteps(activeStep);
};

export default PaymentRun;
