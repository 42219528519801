import { FC } from 'react';
import { Col } from 'components/shared/Col/Col';
import CounterTitle from '../CounterTitle/CounterTitle';
import Placeholder from '../Placeholder/Placeholder';

const TransactionsPlaceholder: FC<{ onlyTransfers?: boolean }> = ({
  onlyTransfers,
}) => (
  <Col mb>
    <CounterTitle mb title={onlyTransfers ? 'Transfers' : 'Transactions'} />
    <Placeholder
      fullWidth
      description={`You will see your ${
        onlyTransfers ? 'transfers' : 'transactions'
      } here when you have some.`}
    />
  </Col>
);

export default TransactionsPlaceholder;
