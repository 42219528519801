import useMFASetup from 'hooks/useMFASetup';
import { FC } from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';
import { useStoreState } from 'state';
import { isEmulated } from 'utils';

const PrivateRoute: FC<RouteProps> = ({ component: Component, ...rest }) => {
  const {
    userEntity,
    isUserRegistrationDone,
    userId,
    isAuthLoading,
  } = useStoreState((state) => state.UserState);
  const { showMFASetup } = useMFASetup();
  const isInCompanyRegistration = rest.location?.pathname.includes(
    'company-registration'
  );

  // wait for isAuthLoading to be false to ensure we don't redirect before we have checked the auth status of the user
  if (!userId && !isAuthLoading) {
    return (
      <Redirect
        to={{
          pathname: '/login',
          state: {
            from: {
              pathname: `${rest.location?.pathname}${
                rest.location?.search ?? ''
              }`,
            },
          },
        }}
      />
    );
  }

  if (!isUserRegistrationDone && !isEmulated()) {
    return <Redirect to="/sign-up?step=3" />;
  }

  if (
    !isInCompanyRegistration &&
    userEntity.status === 'onboarded' &&
    !userEntity.enabled
  ) {
    return <Redirect to="/access-error" />;
  }

  if (showMFASetup && !isInCompanyRegistration) {
    return <Redirect to="/mfa-setup" />;
  }

  return (
    <Route
      {...rest}
      render={(props) => {
        // @ts-expect-error TS(2604) FIXME: JSX element type 'Component' does not have any con... Remove this comment to see the full error message
        return <Component {...props} />;
      }}
    />
  );
};

export default PrivateRoute;
