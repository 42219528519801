import { Col } from '../../../Col/Col';
import { Paragraph } from '../../../Typography/Typography';
import { FC, useEffect, useState } from 'react';
import {
  getInvoiceDetailsFromAttachments,
  getInvoicesPdfAttachments,
} from 'services/firebase/invoices';
import { useStoreState } from 'state';
import { useTheme } from 'styled-components';
import { IInvoiceDetailsFromAttachment } from 'types';
import { errorHandler } from 'utils/errors';
import InvoiceAttachmentsViewer from './components/InvoiceAttachmentsViewer/InvoiceAttachmentsViewer';
import { IImportableValuesFromOcr } from '../../types';
import { notUndefinedTypeGuard } from 'utils';
import { Row } from 'components/shared/Row/Row';
import Pill from 'components/shared/Pill/Pill';
import Loader from 'components/shared/Loader/Loader';
import ImportConfirmationPopup from '../ImportConfirmationPopup/ImportConfirmationPopup';
import { IInvoiceAttachmentsGrouped } from './types';

interface IOwnProps {
  disableCurrency: boolean;
  disableRecipientName: boolean;
  onApplyImportedValues: (values: IImportableValuesFromOcr) => void;
  /** passed when editing a contact */
  sourceSystemContactId?: string;
  existingRecipientName?: string;
  existingCurrency?: string;
}

const InvoicesWithAttachments: FC<IOwnProps> = ({
  disableCurrency,
  disableRecipientName,
  onApplyImportedValues,
  sourceSystemContactId,
  existingRecipientName,
  existingCurrency,
}) => {
  const theme = useTheme();
  const { entityId } = useStoreState(({ UserState }) => UserState);
  const [groupedInvoiceAttachments, setGroupedInvoiceAttachments] = useState<
    IInvoiceAttachmentsGrouped[]
  >([]);
  const [
    selectedGroupOfInvoiceAttachments,
    setSelectedGroupOfInvoiceAttachments,
  ] = useState<IInvoiceAttachmentsGrouped | null>(null);
  const [
    invoiceDetailsFromAttachment,
    setInvoiceDetailsFromAttachment,
  ] = useState<IInvoiceDetailsFromAttachment | null>(null);
  const [
    isLoadingDataFromInvoiceAttachment,
    setIsLoadingDataFromInvoiceAttachment,
  ] = useState(false);
  const [isShowConfirmationPopup, setIsShowConfirmationPopup] = useState(false);
  const [isLoadingInvoices, setIsLoadingInvoices] = useState(true);

  useEffect(() => {
    if (entityId) {
      getInvoicesPdfAttachments(sourceSystemContactId)
        .then((invoiceAttachments) => {
          if (invoiceAttachments.data.data) {
            const groupedInvoiceAttachmentsToSave: IInvoiceAttachmentsGrouped[] = [];

            invoiceAttachments.data.data.forEach((invoiceAttachment) => {
              const existingGroup = groupedInvoiceAttachmentsToSave.find(
                (group) => group.invoiceId === invoiceAttachment.invoiceId
              );

              if (existingGroup) {
                existingGroup.attachments.push(invoiceAttachment);
              } else {
                groupedInvoiceAttachmentsToSave.push({
                  invoiceId: invoiceAttachment.invoiceId,
                  invoiceNumber: invoiceAttachment.invoiceNumber,
                  attachments: [invoiceAttachment],
                });
              }
            });

            setGroupedInvoiceAttachments(groupedInvoiceAttachmentsToSave);
            setIsLoadingInvoices(false);
          }
        })
        .catch(errorHandler);
    }
  }, [sourceSystemContactId, entityId]);

  const importDetailsFromSelectedInvoiceAttachmentData = async (
    data: IInvoiceDetailsFromAttachment
  ) => {
    const {
      accountNumber,
      supplierName,
      supplierEmail,
      supplierAddress,
      currency,
    } = data;

    if (!disableCurrency && currency) {
      const dataToFill = {
        currency,
      };

      onApplyImportedValues(dataToFill);

      // Wait for currency to populate, so we can show correct fields before populating them
      // Not ideal, but a quick win to avoid form refactoring
      await new Promise((resolve) => setTimeout(resolve, 2000));
    }

    const dataToFill: IImportableValuesFromOcr = {
      accountNumber,
      recipientEmail: supplierEmail,
      recipientAddress: supplierAddress,
    };

    if (!disableRecipientName && supplierName) {
      dataToFill.recipientName = supplierName;
    }

    const dataToFillWithoutUndefinedValues = Object.fromEntries(
      Object.entries(dataToFill).filter(([, value]) =>
        notUndefinedTypeGuard(value)
      )
    );

    if (Object.keys(dataToFillWithoutUndefinedValues).length) {
      onApplyImportedValues(dataToFillWithoutUndefinedValues);
    }
  };

  const importDetailsFromSelectedInvoiceAttachment = async (
    attachmentId: string
  ) => {
    try {
      if (!selectedGroupOfInvoiceAttachments) {
        return;
      }

      setIsLoadingDataFromInvoiceAttachment(true);

      const { data } = await getInvoiceDetailsFromAttachments({
        invoiceId: selectedGroupOfInvoiceAttachments.invoiceId,
        attachmentId,
      });

      if (!data.data) {
        return;
      }

      const { supplierName, currency } = data.data;

      if (
        (supplierName &&
          existingRecipientName &&
          existingRecipientName !== supplierName) ||
        (currency && existingCurrency && existingCurrency !== currency)
      ) {
        setInvoiceDetailsFromAttachment(data.data);
        setIsShowConfirmationPopup(true);
        setIsLoadingDataFromInvoiceAttachment(false);
        return;
      }

      await importDetailsFromSelectedInvoiceAttachmentData(data.data);
    } catch (error) {
      errorHandler(error);
    } finally {
      setIsLoadingDataFromInvoiceAttachment(false);
    }
  };

  return (
    <Col flex={1} rowGap={theme.spacing.s}>
      <Row gap={theme.spacing.s} justifyContent="flex-start" flexWrap="wrap">
        {isLoadingInvoices && <Loader size="small" />}
        {!isLoadingInvoices && groupedInvoiceAttachments.length === 0 ? (
          <Paragraph>No invoices with attachments</Paragraph>
        ) : null}
        {groupedInvoiceAttachments.map((invoiceAttachments) => (
          <button
            onClick={() => {
              setSelectedGroupOfInvoiceAttachments(invoiceAttachments);
            }}
            type="button"
            key={invoiceAttachments.invoiceId}
          >
            <Pill
              variant={
                selectedGroupOfInvoiceAttachments?.invoiceId ===
                invoiceAttachments.invoiceId
                  ? 'emerald'
                  : 'standard'
              }
              text={invoiceAttachments.invoiceNumber || 'No reference'}
            />
          </button>
        ))}
      </Row>

      {!!selectedGroupOfInvoiceAttachments && (
        <InvoiceAttachmentsViewer
          invoiceId={selectedGroupOfInvoiceAttachments.invoiceId}
          isFillingFromInvoice={isLoadingDataFromInvoiceAttachment}
          fillFromSelectedInvoiceAttachment={
            importDetailsFromSelectedInvoiceAttachment
          }
        />
      )}

      {isShowConfirmationPopup && (
        <ImportConfirmationPopup
          onClose={() => setIsShowConfirmationPopup(false)}
          onSubmit={(values) =>
            importDetailsFromSelectedInvoiceAttachmentData({
              ...invoiceDetailsFromAttachment,
              ...values,
            })
          }
          existingRecipientName={existingRecipientName ?? ''}
          existingCurrency={existingCurrency ?? ''}
          newRecipientName={invoiceDetailsFromAttachment?.supplierName ?? ''}
          newCurrency={invoiceDetailsFromAttachment?.currency ?? ''}
          disableRecipientName={disableRecipientName}
          disableCurrency={disableCurrency}
        />
      )}
    </Col>
  );
};

export default InvoicesWithAttachments;
