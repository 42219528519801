import {
  Col,
  ContextMenu,
  Icon,
  Paragraph,
  ParagraphWithEllipsis,
  Row,
  StaleInfo,
  TextHint,
} from 'components';
import AmountCell from 'components/shared/AmountCell/AmountCell';
import NumberCircle from 'components/shared/NumberCircle/NumberCircle';
import EditableCell, {
  EditableCellProps,
} from 'pages/BulkUpload/components/EditableCell/EditableCell';
import { Column, Row as ReactTableRow } from 'react-table';
import { ICurrency, Nullable } from 'types';
import {
  IPaymentRunBreakdownByRecipient,
  IPaymentRunBreakdownByRecipientInvoice,
  IPaymentRunItemSummary,
} from 'types/paymentRuns';
import {
  firstCharacterToUppercase,
  parseIntoCurrencyStringWithSymbol,
} from 'utils';
import AuthoriseAmountDueCell from './components/AuthoriseAmountDueCell/AuthoriseAmountDueCell';
import AuthoriseCurrencyCell from './components/AuthoriseCurrencyCell/AuthoriseCurrencyCell';
import AuthoriseGBPCostCell from './components/AuthoriseGBPCostCell/AuthoriseGBPCostCell';
import AuthoriseTransfersCell from './components/AuthoriseTransfersCell/AuthoriseTransfersCell';
import BuyFxCell from './components/BuyFxCell/BuyFxCell';
import ConfirmationIconCell from './components/ConfirmationIconCell/ConfirmationIconCell';
import PaymentFeesCell from './components/PaymentFeesCell/PaymentFeesCell';

const defaultColumns: Column<IPaymentRunItemSummary>[] = [
  {
    accessor: 'invoicesCurrency',
    Header: 'Currency',
    Cell: ({ row, value }) => (
      <AuthoriseCurrencyCell currencyCode={value} valid={row.original.valid} />
    ),
    width: 80,
    minWidth: 80,
  },
  {
    id: 'counts',
    Header: 'Transfers',
    disableSortBy: true,
    Cell: ({ row }: any) => <AuthoriseTransfersCell record={row.original} />,
    width: 110,
  },
  {
    accessor: 'invoicesCount',
    Header: 'Invoices',
    disableSortBy: true,
    Cell: ({ value }) => <NumberCircle value={value} />,
    width: 50,
  },
  {
    accessor: 'invoicesAmountDue',
    Header: 'Amount Due',
    Cell: ({ row, value }) => (
      <AuthoriseAmountDueCell
        totalAmount={value}
        currencyCode={row.original.invoicesCurrency}
      />
    ),
    width: 120,
    minWidth: 100,
  },
];

export const generatePaymentRunTransfersTableColumns = ({
  onEditReference,
  onRemoveTransfer,
  localCurrencyCode,
}: {
  onEditReference: EditableCellProps<IPaymentRunBreakdownByRecipient>['onEdit'];
  onRemoveTransfer: (paymentRunTransferId: string) => Promise<void>;
  localCurrencyCode: string;
}): Column<IPaymentRunBreakdownByRecipient>[] => {
  return [
    {
      accessor: 'recipientName',
      Header: 'Recipient',
      Cell: ({ value, row }) => (
        <Row>
          <ParagraphWithEllipsis mr>{value}</ParagraphWithEllipsis>
          {!row.original.valid && (
            <StaleInfo strategy="fixed" mode="hover" placement="top" portal>
              <Paragraph color="white">{row.original?.error}</Paragraph>{' '}
            </StaleInfo>
          )}
        </Row>
      ),
      width: 160,
      minWidth: 130,
    },
    {
      accessor: 'details',
      Header: 'Details',
      disableSortBy: true,
      Cell: ({ value }) => (
        <ParagraphWithEllipsis>{value}</ParagraphWithEllipsis>
      ),
      width: 110,
    },
    {
      accessor: 'reference',
      Header: 'Reference',
      Cell: ({ value, row }) => (
        <EditableCell
          value={value}
          row={row}
          valuePath="reference"
          onEdit={onEditReference}
        />
      ),
      width: 100,
    },
    {
      Header: 'Method',
      accessor: 'method',
      disableSortBy: true,
      Cell: ({ value }) => {
        return (
          <Paragraph>
            {value === 'local'
              ? firstCharacterToUppercase(value)
              : value.toUpperCase()}
          </Paragraph>
        );
      },
      width: 110,
    },
    {
      accessor: 'amountDue',
      Header: 'Amount Due',
      Cell: ({ value, row }) => (
        <AmountCell
          withFlag
          amount={value}
          currencyCode={row.original.currency}
        />
      ),
      filter: 'direction',
      width: 120,
      minWidth: 100,
    },
    {
      accessor: 'prebooked',
      Header: '',
      Cell: ({ value }) => (value ? <Icon icon="lock-ico" /> : null),
      width: 60,
      minWidth: 60,
      disableSortBy: true,
    },
    {
      accessor: 'localValue',
      Header: `${localCurrencyCode} Value`,
      Cell: ({ value }) => (
        <AmountCell withFlag amount={value} currencyCode={localCurrencyCode} />
      ),
      width: 120,
      minWidth: 100,
    },
    {
      id: 'dots',
      Header: () => null,
      disableSortBy: true,
      Cell: ({ row }: any) => (
        <ContextMenu
          list={[
            {
              id: 'removeTransfer',
              title: 'Remove',
              onClick: () => onRemoveTransfer(row.original.id),
            },
          ]}
          strategy="fixed"
          portal
        />
      ),
      width: 60,
      minWidth: 55,
    },
  ];
};

export const generatePaymentRunInvoicesTableColumns = ({
  onRemoveInvoice,
  localCurrencyCode,
}: {
  onRemoveInvoice: (paymentRunInvoiceId: string) => Promise<void>;
  localCurrencyCode: string;
}): Column<IPaymentRunBreakdownByRecipientInvoice>[] => {
  return [
    {
      accessor: 'valid',
      Header: '',
      Cell: ({ row }) =>
        row.original.valid ? null : (
          <StaleInfo strategy="fixed" mode="hover" placement="top" portal>
            <Paragraph color="white">{row.original?.error}</Paragraph>{' '}
          </StaleInfo>
        ),
      width: 160,
      minWidth: 130,
    },
    {
      accessor: 'details',
      Header: 'Details',
      disableSortBy: true,
      Cell: ({ value }) => <Paragraph>{value}</Paragraph>,
      width: 110,
    },
    {
      accessor: 'reference',
      Header: 'Reference',
      disableSortBy: true,
      Cell: ({ value }) => <Paragraph>{value}</Paragraph>,
      width: 100,
    },
    {
      id: 'method',
      Header: '',
      disableSortBy: true,
      Cell: () => null,
      width: 110,
    },
    {
      accessor: 'amountDue',
      Header: 'Amount Due',
      Cell: ({ value, row }) => (
        <AmountCell
          withFlag
          amount={value}
          currencyCode={row.original.currency}
        />
      ),
      filter: 'direction',
      width: 120,
      minWidth: 100,
    },
    {
      accessor: 'prebooked',
      Header: '',
      Cell: ({ value }) => (value ? <Icon icon="lock-ico" /> : null),
      width: 60,
      minWidth: 60,
      disableSortBy: true,
    },
    {
      accessor: 'localValue',
      Header: `${localCurrencyCode} Value`,
      Cell: ({ value }) => (
        <AmountCell withFlag amount={value} currencyCode={localCurrencyCode} />
      ),
      width: 120,
      minWidth: 100,
    },
    {
      id: 'dots',
      Header: () => null,
      disableSortBy: true,
      Cell: ({ row }: any) => (
        <ContextMenu
          list={[
            {
              id: 'removeInvoice',
              title: 'Remove',
              onClick: () => onRemoveInvoice(row.original.id),
            },
          ]}
          strategy="fixed"
          portal
        />
      ),
      width: 60,
      minWidth: 55,
    },
  ];
};

export const generateAuthoriseTableColumns = ({
  onEdit,
  currencyByCode,
  entityDefaultCurrencyCode,
  paymentDate,
  isLoadingBuyFxAll,
}: {
  onEdit: (
    recordId: string,
    updatedRowData: ReactTableRow['original']
  ) => Promise<void>;
  currencyByCode: (currencyCode: Nullable<string>) => ICurrency | null;
  entityDefaultCurrencyCode: Nullable<string>;
  paymentDate: string;
  isLoadingBuyFxAll: boolean;
}): Column<IPaymentRunItemSummary>[] => [
  ...defaultColumns,
  {
    accessor: 'paymentTotalAmountInLocalCurrency',
    Header: `${entityDefaultCurrencyCode} Cost`,
    Cell: ({ row }) => {
      const {
        localCurrency,
        paymentTotalAmountInLocalCurrency,
        rate,
      } = row.original;

      return (
        <AuthoriseGBPCostCell
          amount={paymentTotalAmountInLocalCurrency}
          rate={rate}
          currencyCode={localCurrency}
          isRateIndicative={!row.original.buyFx}
          isPrebooked={row.original.prebooked}
        />
      );
    },
    width: 80,
    minWidth: 80,
  },
  {
    accessor: 'buyFx',
    Header: 'Buy FX',
    Cell: ({ row, value }) => (
      <BuyFxCell
        value={value}
        row={row}
        onEdit={onEdit}
        isLoadingBuyFxAll={isLoadingBuyFxAll}
        paymentDate={paymentDate}
      />
    ),
    width: 60,
    minWidth: 60,
  },
  {
    accessor: 'paymentFeesAmount',
    Header: 'Payment fees',
    Cell: ({ row, value }) => (
      <PaymentFeesCell
        paymentFeesAmount={value}
        paymentCurrency={row.original.paymentCurrency}
      />
    ),
    width: 80,
    minWidth: 80,
  },
  {
    accessor: 'paymentTotalAmount',
    Header: 'To be paid',
    Cell: ({ row, value }) => {
      const currency = currencyByCode(row.original.paymentCurrency);

      return (
        <Row justifyContent="flex-end" flex={1}>
          <Paragraph variant="bold" mr>
            {parseIntoCurrencyStringWithSymbol(
              value,
              currency?.symbol,
              currency?.precision
            )}
          </Paragraph>
          {currency && <Icon icon={currency.countryCode} />}
        </Row>
      );
    },
    width: 80,
    minWidth: 80,
  },
];

export const generatePaymentRunOpenBankingTableColumns = (): Column<IPaymentRunItemSummary>[] => [
  ...defaultColumns,
  {
    id: 'info',
    Header: () => null,
    disableSortBy: true,
    Cell: () => (
      <Paragraph>
        Payment fees may apply as per your bank's terms and conditions
      </Paragraph>
    ),
    width: 300,
  },
];

export const generateConfirmationTableColumns = ({
  currencyByCode,
  entityDefaultCurrencyCode,
}: {
  currencyByCode: (currencyCode: Nullable<string>) => ICurrency | null;
  entityDefaultCurrencyCode: Nullable<string>;
}): Column<IPaymentRunItemSummary>[] => [
  {
    accessor: 'invoicesCurrency',
    Header: 'Currency',
    Cell: ({ value }) => <AuthoriseCurrencyCell currencyCode={value} valid />,
    width: 80,
    minWidth: 80,
  },
  {
    id: 'counts',
    Header: 'Transfers',
    disableSortBy: true,
    Cell: ({ row }: any) => <AuthoriseTransfersCell record={row.original} />,
    width: 110,
  },
  {
    accessor: 'invoicesCount',
    Header: 'Invoices',
    disableSortBy: true,
    Cell: ({ value }) => <NumberCircle value={value} />,
    width: 50,
  },

  {
    accessor: 'invoicesAmountDue',
    Header: 'Amount Due',
    Cell: ({ row, value }) => (
      <AuthoriseAmountDueCell
        totalAmount={value}
        currencyCode={row.original.invoicesCurrency}
      />
    ),
    width: 120,
    minWidth: 100,
  },
  {
    accessor: 'paymentTotalAmountInLocalCurrency',
    Header: `${entityDefaultCurrencyCode} Cost`,
    Cell: ({ row }) => {
      const {
        localCurrency,
        paymentTotalAmountInLocalCurrency,
        rate,
        buyFx,
      } = row.original;

      if (buyFx) {
        return (
          <AuthoriseGBPCostCell
            amount={paymentTotalAmountInLocalCurrency}
            rate={rate}
            currencyCode={localCurrency}
          />
        );
      }

      return null;
    },
    width: 80,
    minWidth: 80,
  },
  {
    accessor: 'buyFx',
    Header: '',
    Cell: ({ row }) => <ConfirmationIconCell row={row} />,
    width: 60,
    minWidth: 60,
    disableSortBy: true,
  },
  {
    accessor: 'paymentFeesAmount',
    Header: 'Payment fees',
    Cell: ({ row, value }) => {
      const currency = currencyByCode(row.original.paymentCurrency);

      return (
        <Col>
          <Paragraph variant="bold">
            {parseIntoCurrencyStringWithSymbol(value, currency?.symbol, 0)}
          </Paragraph>
          <TextHint>SWIFT fees</TextHint>
        </Col>
      );
    },
    width: 80,
    minWidth: 80,
  },
  {
    accessor: 'paymentTotalAmount',
    Header: 'To be paid',
    Cell: ({ row, value }) => {
      const currency = currencyByCode(row.original.paymentCurrency);

      return (
        <Row justifyContent="flex-end" flex={1}>
          <Paragraph variant="bold" mr>
            {parseIntoCurrencyStringWithSymbol(
              value,
              currency?.symbol,
              currency?.precision
            )}
          </Paragraph>
          {currency && <Icon icon={currency.countryCode} />}
        </Row>
      );
    },
    width: 80,
    minWidth: 80,
  },
];
